const dev = {
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://d83tyt1jw7.execute-api.us-east-1.amazonaws.com/dev",
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_1vWQzvn1y",
    APP_CLIENT_ID: "4htlo41aehdo3105kvt9c6bjob",
    IDENTITY_POOL_ID: "us-east-1:cd01f8e8-04ad-4872-8d9b-9016b2e4ec8d",
  },
  s3: {
    BUCKET: "wishbone-api-dev-attachmentsbucket-mdy5zdn3z3wr",
    REGION: "us-east-1",
  },
  mixpanel: {
    PROJECT_TOKEN: "a4fdd74c55729e1defb451c6b310cae3",
  },
};

const prod = {
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://5ajjxdf0r4.execute-api.us-east-1.amazonaws.com/prod"
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_oUlZmXDZB",
    APP_CLIENT_ID: "4suho5asgp11gbtc88jr4ps9db",
    IDENTITY_POOL_ID: "us-east-1:3dec1560-56d8-48b4-a25d-e9ebeb025b8f"
  },
  s3: {
    BUCKET: "wishbone-api-prod-attachmentsbucket-wu2q55muin8n",
    REGION: "us-east-1",
  },
  mixpanel: {
    PROJECT_TOKEN: "9d9dfb0fc3f6477a69abd584fecadd9d",
  },
};

// Default to dev if not set
const config = process.env.REACT_APP_STAGE === 'prod'
  ? prod
  : dev;

// extend with common config values
Object.assign(config, {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 5000000,
});

export default config;
