import React, { Suspense } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import AppliedRoute from "./components/AppliedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";
import AuthenticatedRoute from "./components/AuthenticatedRoute";

// import all the views
const Landing = React.lazy(() => import("views/Landing"));
const Discover = React.lazy(() => import("views/Discover"));
const ItemDetails = React.lazy(() => import("views/ItemDetails"));
const Setup = React.lazy(() => import("views/Setup"));
const Dashboard = React.lazy(() => import("views/Dashboard"));
const FriendWishlist = React.lazy(() => import("views/FriendWishlist"));
const Search = React.lazy(() => import("views/Search"));
const Login = React.lazy(() => import("views/Login"));
const AddBookmarklet = React.lazy(() => import("views/AddBookmarklet"));
// const ReportBug = React.lazy(() => import("views/ReportBug"));
const PrivacyPolicy = React.lazy(() => import("views/PrivacyPolicy"));
const TermsOfService = React.lazy(() => import("views/TermsOfService"));
const Invite = React.lazy(() => import("views/Invite"));
const Oauth2Callback = React.lazy(() => import("views/Oauth2Callback"));
const ErrorNotFound = React.lazy(() => import("views/ErrorNotFound"));

export default function Routes({ childProps }) {
  return (
    <BrowserRouter>
      <Suspense fallback={<div>Loading...</div>}>
        <Switch>
          <AppliedRoute exact path="/" component={childProps.isAuthenticated ? Dashboard : Landing} props={childProps} />
          <AppliedRoute exact path="/discover" component={Discover} props={childProps} />
          <AppliedRoute exact path="/search" component={Search} props={childProps} />
          <AppliedRoute exact path="/users/:id" component={FriendWishlist} props={childProps} />
          <AppliedRoute exact path="/add-bookmarklet" component={AddBookmarklet} props={childProps} />
          {/* <AppliedRoute exact path="/report-bug" component={ReportBug} props={childProps} /> */}
          <AppliedRoute exact path="/privacy-policy" component={PrivacyPolicy} props={childProps} />
          <AppliedRoute exact path="/terms" component={TermsOfService} props={childProps} />
          <AuthenticatedRoute exact path="/setup" component={Setup} props={childProps} />
          <AuthenticatedRoute exact path="/dashboard" component={Dashboard} props={childProps} />
          <AuthenticatedRoute exact path="/items/new" component={ItemDetails} props={childProps} />
          <AuthenticatedRoute exact path="/items/:id" component={ItemDetails} props={childProps} />
          <AuthenticatedRoute exact path="/invite" component={Invite} props={childProps} />
          <AuthenticatedRoute exact path="/oauth2callback" component={Oauth2Callback} props={childProps} />
          <UnauthenticatedRoute exact path="/login" component={Login} props={childProps} />
          <UnauthenticatedRoute exact path="/register" component={Login} props={childProps} />
          { /* Finally, catch all unmatched routes */ }
          <Route component={ErrorNotFound} />
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
}
