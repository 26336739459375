import React, { Suspense } from "react";
import { Auth, API } from "aws-amplify";
import { withRouter } from "react-router-dom";
import Routes from "./Routes";
import config from "./config";
import "./App.css";
import mixpanel from "mixpanel-browser";
mixpanel.init(config.mixpanel.PROJECT_TOKEN);

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isAuthenticated: false,
      isAuthenticating: true,
      userMetadata: {}, // use an object to avoid null pointer errors
    };

    this.updateUserMetadata = this.updateUserMetadata.bind(this);
  }

  async componentDidMount() {
    try {
      // Auth.currentSession is crucial because it's how we determine if the user is logged in
      const session = await Auth.currentSession();
      this.userHasAuthenticated(true);

      // mixpanel
      const userId = session.idToken.payload["custom:cognito_identity_id"].split(":")[1];
      mixpanel.identify(userId);
      mixpanel.track("Loaded App.js component");
    } catch(e) {
      if (e !== "No current user") {
        console.error(e);
      }
    }

    this.setState({ isAuthenticating: false });
  }

  userHasAuthenticated = authenticated => {
    this.setState({ isAuthenticated: authenticated });
    authenticated && this.setUserMetadata();
  }

  getUserMetadata() {
    return API.get("users", "/users");
  }

  async setUserMetadata() {
    if (Object.keys(this.state.userMetadata).length === 0) {
      let userMetadata;
      try {
        userMetadata = await this.getUserMetadata();
      } catch (error) {
        // - This function can return an error if userMetadata was never set upon user creation
        // - This edge case can happen if a user does not complete signup in one go, but instead
        //   comes back and successfully confirms their email with the wrong password.
        // - We can recover from this situation by saving userMetadata for the first time
        // - TODO: this is duplicated from the RegisterForm; we need to de-duplicate
        const authenticatedUser = await Auth.currentAuthenticatedUser();
        userMetadata = await API.post("users", "/users", {
          body: {
            userPoolUsername: authenticatedUser.username,
            email: authenticatedUser.attributes.email,
            firstName: authenticatedUser.attributes.given_name,
            lastName: authenticatedUser.attributes.family_name,
          }
        });
        // save federated identity id as a custom attribute upon first login
        const currentUserInfo = await Auth.currentUserInfo();

        await Auth.updateUserAttributes(authenticatedUser, {
          "custom:cognito_identity_id": currentUserInfo.id,
        });
      }
      this.setState({ userMetadata });
    }
  }

  updateUserMetadata(newMetadata) {
    this.setState({
      userMetadata: newMetadata,
    });
  }

  handleLogout = async event => {
    await Auth.signOut();
    // wipe any userMetadata
    this.setState({
      userMetadata: {},
    });

    this.userHasAuthenticated(false);
    this.props.history.push("/");
  }

  render() {
    const childProps = {
      isAuthenticated: this.state.isAuthenticated,
      userHasAuthenticated: this.userHasAuthenticated,
      handleLogout: this.handleLogout,
      userMetadata: this.state.userMetadata,
      onMetadataUpdate: this.updateUserMetadata,
      mixpanel: mixpanel,
    };

    return (
      !this.state.isAuthenticating && (
        <Suspense fallback={<div>Loading...</div>}>
          <Routes childProps={childProps} />
        </Suspense>
      )
    );
  }
}

export default withRouter(App);
